import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { H2, P } from '../../util/standard'
import { colors, TABLET_BREAKPOINT, SMALL_LAPTOP_BREAKPOINT, MOBILE_BREAKPOINT } from '../../util/constants'

const Wrapper = styled.div`
width: 65%;
margin: 0 auto;

@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  width: 80%;
}
`

const IntroWrapper = styled.div`
margin: 150px auto 40px auto;
`

const Header = styled(H2)`
 color: ${colors.black};
 font-size: 24px;
`

const ContactDetails = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
margin: 0 auto 50px auto;
width: 100%;
grid-gap:60px;

@media (max-width:${SMALL_LAPTOP_BREAKPOINT}px){
  grid-gap:40px;
}

@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  grid-template-columns: repeat(1, 1fr);
  grid-gap:20px;
}
`

const DetailWrapper = styled.div<{ borderRight?: boolean }>`
${({ borderRight }) => borderRight && `border-right: 1px solid #D9D9D9;
@media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  border: none;
}
`};
padding-right: 60px;
@media (max-width:${SMALL_LAPTOP_BREAKPOINT}px){
  padding-right:40px;
}
`

function ContactIntro() {

  const { sanityContactUs } = useStaticQuery(graphql`
    {
    sanityContactUs {
      header
      phoneNumber
      subText
      emailAddress
      address
    }
  }
  `)

  const { header, subText, phoneNumber, emailAddress, address } = sanityContactUs

  return (
    <Wrapper>
      <IntroWrapper>
        <Header fontWeight='bold'>{header}</Header>
        <P>{subText}</P>
      </IntroWrapper>
      <ContactDetails>
        <DetailWrapper borderRight>
          <P marginBottom={5} color='green' fontWeight='bold'>Phone</P>
          <P>{phoneNumber}</P>
        </DetailWrapper>
        <DetailWrapper borderRight>
          <P marginBottom={5} color='green' fontWeight='bold'>Email Address</P>
          <P>{emailAddress}</P>
        </DetailWrapper>
        <DetailWrapper>
          <P marginBottom={5} color='green' fontWeight='bold'>Address</P>
          <P>{address}</P>
        </DetailWrapper>
      </ContactDetails>
    </Wrapper>
  )
}

export default ContactIntro
