import React, { ChangeEvent, useState } from "react";
import { TABLET_BREAKPOINT, colors } from "../../util/constants";

import Input from "../shared/sub/Input";
import { navigate } from "gatsby";
import styled from "styled-components";

const axios = require("axios");
const FormWrapper = styled.form`
  width: 66%;
  margin: 0 auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 82%;
  }
`;

const Wrapper = styled.div`
  margin-top: 15px;
`;

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledInput = styled.input`
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  width: 100%;
  ::placeholder {
    color: #666666;
  }
`;

const StyledTextarea = styled.textarea`
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  height: 120px;
  width: 99%;
  margin: 0px 30px 0 0;
  display: block;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 97%;
  }

  ::placeholder {
    color: #666666;
  }
`;

const StyledLabel = styled.label`
  margin: 10px 15px 0 0;
  font-weight: bold;
`;
const SubmitButton = styled.button`
  text-align: center;
  display: block;
  color: white;
  width: 10%;
  min-width: 90px;
  background-color: #00a032;
  padding: 10px 20px;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: ${colors.blue};
  }

  transition: background-color 0.2s;
`;

function ContactForm() {
  const state: any = {
    firstname: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formState, updateFormState] = useState(state);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedEmail = {
      from: "admin@wcf.kiwi",
      to: "admin@wcf.kiwi",
      subject: formState.subject,
      html: `<h1>Contact form submitted by ${formState.firstname} ${formState.lastname}</h1>
      <p>Email Address: ${formState.email}</p>
      <p>${formState.message}</p>
        `,
    };

    await axios
      .post(".netlify/functions/sendEmail", JSON.stringify(formattedEmail))
      .then(function (response: any) {
        console.log(response, "response");
        navigate("/thankyou");
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };

  return (
    <FormWrapper
      name="contact"
      onSubmit={handleSubmit}
      data-netlify="true"
      method="POST"
    >
      <InputGrid>
        <Input name="form-name" inputType="hidden" />
        <StyledLabel>
          First Name
          <StyledInput
            name="firstname"
            type="text"
            placeholder="First Name"
            required
            onChange={e => {
              updateFormState({ ...formState, firstname: e.target.value });
            }}
          />
        </StyledLabel>
        <StyledLabel>
          Last Name
          <StyledInput
            name="lastname"
            type="text"
            placeholder="Last Name"
            required
            onChange={e => {
              updateFormState({ ...formState, lastname: e.target.value });
            }}
          />
        </StyledLabel>
        <StyledLabel>
          Email Address
          <StyledInput
            name="email"
            type="email"
            placeholder="Email Address"
            required
            onChange={e => {
              updateFormState({ ...formState, email: e.target.value });
            }}
          />
        </StyledLabel>
        <StyledLabel>
          Subject
          <StyledInput
            name="subject"
            type="text"
            placeholder="Subject"
            required
            onChange={e => {
              updateFormState({ ...formState, subject: e.target.value });
            }}
          />
        </StyledLabel>
      </InputGrid>
      <Wrapper>
        <StyledLabel>
          Message
          <StyledTextarea
            name="message"
            placeholder="Message"
            required
            onChange={e => {
              updateFormState({ ...formState, message: e.target.value });
            }}
          />
        </StyledLabel>
        <SubmitButton type="submit">Submit</SubmitButton>
      </Wrapper>
    </FormWrapper>
  );
}

export default ContactForm;
