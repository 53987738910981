import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../components/shared/Hero";
import ContactForm from "../components/contact/ContactForm";
import ContactIntro from "../components/contact/ContactIntro";
import DonateBlock from "../components/shared/DonateBlock";
import SEO from "../components/shared/Seo";
import styled from "styled-components";

const StyledIframe = styled.iframe`
  width: 100%;
  height: 700px;
  border: none;
`;

function contact() {
  const { sanityContactUs } = useStaticQuery(graphql`
    {
      sanityContactUs {
        contactHero {
          heroImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                ...GatsbySanityImageFluid
              }
              title
            }
          }
          heroText
        }
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const { contactHero } = sanityContactUs;
  return (
    <>
      <SEO seoData={sanityContactUs?.seoBlock} slug="contact" />
      <Hero
        heroText={contactHero.heroText}
        heroImage={contactHero.heroImage}
        blockVisible
        blockTextOverwrite="GET IN TOUCH"
      />
      <ContactIntro />
      <ContactForm />
      {/* <StyledIframe
        src="https://connect.vega.works/content/csharp/contact.aspx?rfD3x0k=0988F320-AF69-4AB3-B22E-C4050EC7B9DC&xlnse5=2365"
        referrerpolicy="always"
        onload="this.contentWindow.postMessage(window.location.href, 'https://connect.vega.works')"
      ></StyledIframe> */}
      <DonateBlock />
    </>
  );
}

export default contact;
