import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../util/constants";

interface Props {
  inputType?: string;
  placeHolder?: string;
  isTextArea?: boolean;
  isCheckbox?: boolean;
  required?: boolean;
  onChange?: () => void;
  name?: string;
}

const InputBlock = styled.input`
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  margin: 1.5%;

  ::placeholder {
    color: #666666;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  height: 120px;
  width: 98.3%;
  margin: 1% 0.8%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 97%;
    margin: 1% 1.4%;
  }

  ::placeholder {
    color: #666666;
  }
`;

const InputLabel = styled.label`
  position: absolute;
  width: 0%;
  opacity: 0;
  z-index: -9999;
`;

function Input(props: Props) {
  const {
    inputType,
    placeHolder,
    isTextArea,
    isCheckbox,
    onChange,
    required,
    name,
  } = props;
  if (!isTextArea && !isCheckbox) {
    return (
      <>
        <InputLabel htmlFor={placeHolder}>{placeHolder}</InputLabel>
        <InputBlock
          name={name}
          required={required}
          type={inputType ? inputType : "text"}
          onChange={onChange}
          placeholder={placeHolder ? placeHolder : ""}
          id={placeHolder}
        />
      </>
    );
  } else if (isTextArea) {
    return (
      <>
        <InputLabel htmlFor={placeHolder}>{placeHolder}</InputLabel>
        <TextArea
          name={name}
          placeholder={placeHolder}
          required={required}
          onChange={onChange}
          id={placeHolder}
        />
      </>
    );
  } else {
    return (
      <>
        <InputBlock
          name={name}
          type="checkbox"
          id={placeHolder}
          onChange={onChange}
          required={required}
        />
        <label htmlFor={placeHolder}>{placeHolder}</label>
      </>
    );
  }
}

export default Input;
